

import { useParams } from "react-router-dom"
export default function User() {
    const {userID} = useParams();
    //Note: name here should match the name we have given in index.js so we gave userID there hence we need to use the same


    return (
        <>
        <h1 className="text-center">User: {userID}</h1>
        </>
    )

}