
import React from "react";
import Header from "../header/Header";
import Home from "../home/Home";
import Footer from "../footer/Footer";
import About from "../About/About";
import { Outlet } from "react-router-dom";


// Outlet is being used to conditonaly invoke the middle component
function Layout() {
  return (
    <>
    <div>
        <Header/>
        <Outlet />
        <Footer/>
        
    </div>
    </>
);
}

export default Layout