import React, { Component } from 'react';

class UpdateStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [
        { name: 'Product 1', quantity: 50, image: 'https://storage.googleapis.com/a1aa/image/8CXyz3lBSLrjMJecxUjiDKf0WmcteXzIezg8MB0zOmG1OhMPB.jpg' },
        { name: 'Product 2', quantity: 30, image: 'https://storage.googleapis.com/a1aa/image/BH7JBeb5jTTlSStmHU5vmDvGg8ziI4VykaFTGYjeyG5rTIzTA.jpg' },
        { name: 'Product 3', quantity: 20, image: 'https://storage.googleapis.com/a1aa/image/cxpQfr2dzaXeQ0yNceTWugHy51izHPEzXNJWNveIotnhOhMPB.jpg' },
        { name: 'Product 4', quantity: 15, image: 'https://storage.googleapis.com/a1aa/image/zg4ejT6UNfnhVEcUP86aJ6SfMbf4KhDypyfe7kaCZ2s95Ey8E.jpg' },
        { name: 'Product 5', quantity: 10, image: 'https://storage.googleapis.com/a1aa/image/lzxK9NvTUV5hEd8jf6GIUS42QlytY1l41AeT2yrZPQWqTIzTA.jpg' },
        { name: 'Product 6', quantity: 5, image: 'https://storage.googleapis.com/a1aa/image/qkVqXf9et3nfvJOLepl2K4unjMV9WWO6O7Vt4us3TWmnOhMPB.jpg' }
      ],
      selectedProduct: '',
      selectedQuantity: 0,
      selectedImage: '',
      modalOpen: false, // Track if the modal is open
    };
  }

  // Handle product selection and open modal
  handleProductSelect = (product) => {
    this.setState({
      selectedProduct: product.name,
      selectedQuantity: product.quantity,
      selectedImage: product.image,
      modalOpen: true // Open the modal when a product is selected
    });
  };

  // Handle quantity change in modal
  handleQuantityChange = (event) => {
    this.setState({ selectedQuantity: event.target.value });
  };

  // Submit the form to update stock
  handleSubmit = (event) => {
    event.preventDefault();
    const updatedProducts = [...this.state.products];
    const updatedProduct = updatedProducts.find(product => product.name === this.state.selectedProduct);
    updatedProduct.quantity = parseInt(this.state.selectedQuantity, 10);
    
    this.setState({
      products: updatedProducts,
      modalOpen: false // Close the modal after submitting the form
    });
    
    alert('Stock updated successfully!');
  };

  // Close the modal
  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    return (
      <div className="container mx-auto px-4 mt-8">
        {/* Product List Section */}
        <section className="bg-white p-6 rounded shadow-md mb-8">
          <h2 className="text-xl font-bold mb-4">Available Stock</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
            {this.state.products.map((product) => (
              <div key={product.name} className="bg-gray-100 p-4 rounded shadow">
                <img
                  alt={`Image of ${product.name}`}
                  className="w-full h-48 object-cover rounded mb-4 cursor-pointer"
                  src={product.image}
                  onClick={() => this.handleProductSelect(product)} // Open modal on click
                />
                <h3 className="text-lg font-bold">{product.name}</h3>
                <p className="text-gray-700">Quantity: {product.quantity}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Modal for Update Stock Form */}
        {this.state.modalOpen && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded shadow-lg w-96 sm:w-11/12 md:w-96 lg:w-96">
              <h2 className="text-xl font-bold mb-4">Update Inventory Stock</h2>
              <form onSubmit={this.handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700" htmlFor="product-name">
                    Product Name
                  </label>
                  <input
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    id="product-name"
                    value={this.state.selectedProduct}
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700" htmlFor="product-quantity">
                    Quantity
                  </label>
                  <input
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    id="product-quantity"
                    type="number"
                    value={this.state.selectedQuantity}
                    onChange={this.handleQuantityChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700" htmlFor="product-image">
                    Product Image URL
                  </label>
                  <input
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    id="product-image"
                    value={this.state.selectedImage}
                    disabled
                  />
                </div>
                <div className="flex justify-between space-x-4">
                  <button
                    type="button"
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={this.closeModal} // Close modal without submitting
                  >
                    Close
                  </button>
                  <button className="bg-blue-600 text-white px-4 py-2 rounded" type="submit">
                    Update Stock
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UpdateStock;
