import { useState } from "react"
import { useEffect } from "react"
import { useLoaderData } from "react-router-dom"

export default function Github() {

    //  const [data, setData] = useState([])
    // useEffect(() => {
    //  fetch('https://api.github.com/users/hiteshchoudhary')
    //  .then(response => response.json())
    //  .then(data => {
    //     console.log(data);
    //     setData(data)
    //  })
    // }, [])
    

    //writing above method using loader
    const data = useLoaderData()
    return (
        <>
        <div
        className="text-center m-4 bg-green-500 text-white text-3xl p-4">
            <h1 className="">Github Follower:  {data.followers}</h1>
            <img className="text-center p-4 mx-10 rounded-3xl m-auto" src={data.avatar_url} alt="avatar_url" width={300}  />
            </div>
        </>

    )

}

export const githubInfoLoader = async () => {
    const response = await fetch('https://api.github.com/users/hiteshchoudhary')
    return response.json()
}