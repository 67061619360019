import { Link } from "react-router-dom";
import React from "react";

export default function Home() {
    return (
        <div className="mx-auto w-full max-w-7xl">
            <section className="relative overflow-hidden text-white rounded-lg bg-gradient-to-r from-green-400 to-blue-500 sm:mx-16 mx-2 sm:py-16 py-10">
                <div className="relative z-10 max-w-screen-xl px-4 pb-20 pt-10 sm:py-24 mx-auto sm:px-6 lg:px-8">
                    <div className="max-w-xl sm:mt-1 mt-20 space-y-8 text-center sm:text-left sm:ml-auto">
                        <h2 className="text-4xl font-extrabold sm:text-5xl leading-tight">
                            Manage Your Inventory Like a Pro
                            <span className="block text-lg sm:text-2xl mt-4 font-light">Streamline your workflow and track your assets with ease.</span>
                        </h2>

                        <p className="text-xl font-medium mt-4 sm:text-left">
                            Our powerful inventory management platform helps you keep track of your stock, manage orders, and optimize your supply chain, all from a user-friendly dashboard.
                        </p>

                    </div>
                </div>

                <div className="absolute inset-0 sm:top-1/2 sm:left-1/4 top-12 left-1/8 h-full opacity-60">
                    <img 
                        className="w-96 sm:w-full object-cover"
                        src="https://i.ibb.co/5BCcDYB/Remote2.png" 
                        alt="Inventory Management"
                    />
                </div>
            </section>

            <section className="mt-20 px-4 text-center">
                <h3 className="text-3xl font-semibold mb-6">Features You'll Love</h3>

                <div className="grid grid-cols-1 sm:grid-cols-3 gap-10">
                    <div className="p-6 bg-white shadow-lg rounded-lg">
                        <h4 className="text-2xl font-semibold mb-4">Real-Time Tracking</h4>
                        <p className="text-gray-600">Track stock levels in real-time, so you're never caught off guard. Monitor your inventory status instantly from anywhere.</p>
                    </div>

                    <div className="p-6 bg-white shadow-lg rounded-lg">
                        <h4 className="text-2xl font-semibold mb-4">Efficient Reporting</h4>
                        <p className="text-gray-600">Generate comprehensive reports that give you insight into your stock movements, sales patterns, and reorder points.</p>
                    </div>

                    <div className="p-6 bg-white shadow-lg rounded-lg">
                        <h4 className="text-2xl font-semibold mb-4">Seamless Integration</h4>
                        <p className="text-gray-600">Integrate with your existing systems like ERP and POS for a smooth and automated experience across your entire operation.</p>
                    </div>
                </div>
            </section>

            <section className="py-20 bg-gray-100 mt-16">
                <div className="text-center">
                    <h3 className="text-3xl font-semibold mb-6">How It Works</h3>
                    <p className="text-xl mb-6 text-gray-700">Simple, powerful, and intuitive. Start managing your inventory effortlessly with just a few steps.</p>
                </div>

                <div className="flex justify-center space-x-8">
                    <div className="text-center p-6 bg-white shadow-lg rounded-lg">
                        <h4 className="text-xl font-semibold mb-4">Step 1: Add Your Products</h4>
                        <p className="text-gray-600">Upload your products with ease using bulk imports or manually. Manage categories, prices, and other key details.</p>
                    </div>

                    <div className="text-center p-6 bg-white shadow-lg rounded-lg">
                        <h4 className="text-xl font-semibold mb-4">Step 2: Set Alerts</h4>
                        <p className="text-gray-600">Set automated alerts to notify you when stock is running low or when orders need attention. Stay in control at all times.</p>
                    </div>

                    <div className="text-center p-6 bg-white shadow-lg rounded-lg">
                        <h4 className="text-xl font-semibold mb-4">Step 3: Track and Optimize</h4>
                        <p className="text-gray-600">Monitor stock movement, analyze trends, and optimize your stock levels to avoid overstocking or stockouts.</p>
                    </div>
                </div>
            </section>

            <footer className="bg-gray-800 text-center py-6">
                <p className="text-white">© 2024 Thunder Tribes. All Rights Reserved.</p>
            </footer>
        </div>
    );
}
