import React from "react";

export default function Login() {
return (
<section class="bg-white p-6 rounded shadow-md mb-8" id="signin">
    <h2 class="text-xl font-bold mb-4">
     Vendor Sign In
    </h2>
    <form>
     <div class="mb-4">
      <label class="block text-gray-700" for="email">
       Email
      </label>
      <input class="w-full p-2 border border-gray-300 rounded mt-1" id="email" required="" type="email"/>
     </div>
     <div class="mb-4">
      <label class="block text-gray-700" for="password">
       Password
      </label>
      <input class="w-full p-2 border border-gray-300 rounded mt-1" id="password" required="" type="password"/>
     </div>
     <button class="bg-blue-600 text-white px-4 py-2 rounded" type="submit">
      Sign In
     </button>
    </form>
   </section>
   )
}