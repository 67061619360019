import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Home from './components/home/Home';
import Layout from './components/Layout/Layout';
import About from './components/About/About';
import Contact from './components/Contact/Contacts';
import User from './components/User/User';
import Github, { githubInfoLoader } from './components/Github/Github';
import Login from './components/Login/Login';
import UpdateStock from './components/UpdateStock/UpdateStock';

// Below is one way of writing browserROuter and there is another way aswell
// const router = createBrowserRouter([{
//   path: '/',
//   element: <Layout/>,
//   children:[
//     {
//       path:"",
//       element: <Home/>
//     },
//     {
//       path:"about",
//       element:<About/>
//     }
//     ,
//     {
//       path:"contact",
//       element:<Contact/>
//     }
//   ]
// }])


//This is the second way of writing the above
const router =createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout/>}>
      <Route path='' element={<Home/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/viewstock' element={<UpdateStock/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/User/:userID' element={<User/>}/>
      <Route 
      loader={githubInfoLoader}
      path='/github' element={<Github/>}/>
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
